import dynamic             from "next/dynamic";
import { useRouter }       from "next/router";
import React, { useState } from "react";

import { useGetLoggedIn }          from "_common/hooks/auth";
import { useSetIsSidebarMenuOpen } from "_common/hooks/global";
import { useGetIsSidebarMenuOpen } from "_common/hooks/global";
import { useGetDeviceType }        from "_common/hooks/global";
import { isCreatorDashboardPage }  from "_common/utils";

const Presentation       = dynamic ( () => import ( "./presentation" ) );
const MobilePresentation = dynamic ( () => import ( "./mobile.presentation" ) );

const component = ( { fullWidth } ) => {

	const deviceType         = useGetDeviceType ();
	const isMobile           = deviceType === "mobile";
	const router             = useRouter ();
	const toggleSidebarMenu  = useSetIsSidebarMenuOpen ();
	const isUserLoggedIn     = useGetLoggedIn ();
	const isSidebarMenuOpen  = useGetIsSidebarMenuOpen ();
	const isCreatorDashboard = isCreatorDashboardPage ( { router } );

	const [ showSearchModal, setShowSearchModal ] = useState ( false );

	const isStudioPage = router && router.pathname.includes ( "/studio" );

	const onClickHamburgerMenu = () => {
		toggleSidebarMenu ( !isSidebarMenuOpen );
	};

	const toggleSearchModal = () => {
		setShowSearchModal ( prevState => !prevState );
	};

	if ( isMobile && !isCreatorDashboard ) {
		return (
			<MobilePresentation
				isUserLoggedIn    = { isUserLoggedIn }
				showSearchModal   = { showSearchModal }
				toggleSearchModal = { toggleSearchModal }
				toggleSidebarMenu = { toggleSidebarMenu }
			/>
		);
	}

	return (
		<Presentation
			fullWidth            = { fullWidth }
			isMobile             = { isMobile }
			isStudioPage         = { isStudioPage }
			isUserLoggedIn       = { isUserLoggedIn }
			onClickHamburgerMenu = { onClickHamburgerMenu }
			router               = { router }
		/>
	);
};

export default component;
